import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CustomInput from "../../components/material/CustomInput/CustomInput";
import logo from '../../components/Sightana_Logo.svg'
import {
  almostWhite, customInputDangerColor,
  primaryDarkestColor,
  sightanaOrange
} from '../../components/assets/jss/colors'
import store from '../../store'
import { useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import validator from 'validator'
import { Auth } from "aws-amplify";
import { useHistory } from 'react-router-dom'

const styles = (theme) => ({
  loginPaper: {
    width: '400px',
    margin: 'auto',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  loginWrapper: {
    margin: '40px 16px',
  },
  loginLogo: {
    width: "150px",
    padding: "20px",
  },
  loginError: {
    color: customInputDangerColor,
    fontWeight: "900",
    textAlign: "center",
    width: "100%",
    padding: "10px",
    margin: "10px",
    display: "block",
  },
  loginButton: {
    background: almostWhite,
    width: "100%",
    color: primaryDarkestColor,
    "&:hover": {
      fontWeight: "bold",
      background: sightanaOrange,
      color: primaryDarkestColor
    }
  },
});

function handleChange(event) {
  store.dispatch({ type: 'auth/'+event.currentTarget.id, payload: event.currentTarget.value })
}

function setError(error) {
  store.dispatch({ type: 'auth/error', payload: error })
}

async function handleLogin(event, auth, history) {
  event.preventDefault();

  try {
    await Auth.signIn(auth.email, auth.password);
    store.dispatch({ type: 'auth/login' })
    history.push("/");
  } catch (e) {
    store.dispatch({ type: 'auth/logout' })
    setError(e.message);
  }
}

function validateForm(auth) {
  let result = true;

  if (auth.email === null || auth.email === undefined || auth.email.length === 0) {
    result = false;
  } else if (!validator.isEmail(auth.email)) {
    result = false;
  }

  if (auth.password === null || auth.password === undefined || auth.password.length === 0) {
    result = false;
  }

  return result;
}


const selectAuth = state => state.auth;

function LoginBody(props) {
  const { classes } = props;
  const auth = useSelector(selectAuth)
  const history = useHistory();

  return (
    <Paper className={classes.loginPaper}>
      <div className={classes.loginWrapper}>
        <img className={classes.loginLogo} src={logo} alt="Sightana, Inc."/>
        <div>
          <Form className="form" onSubmit={(event) => (handleLogin(event, auth, history))}>
            <Form.Group size="lg" controlId="email">
            <CustomInput
              autoFocus
              key="email"
              labelText="Email"
              id="email"
              formControlProps={{
                fullWidth: true
              }}
              handleChange={handleChange}
              type="email"
            />
            </Form.Group>
            <Form.Group size="lg" controlId="password">
            <CustomInput
              key="password"
              labelText="Password"
              id="password"
              formControlProps={{
                fullWidth: true
              }}
              handleChange={handleChange}
              type="password"
            />
            </Form.Group>

            <Button type="submit" color="primary" className={classes.loginButton}
                    disabled={!validateForm(auth)}>
              Log in
            </Button>
            <span className={classes.loginError}>{auth.error}</span>
          </Form>
        </div>
      </div>
    </Paper>
  );
}

LoginBody.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginBody);

import VignetteBody from './body'
import React from 'react'
import DnsRoundedIcon from '@material-ui/icons/DnsRounded'

const VignetteDefinition = {
  path: "/vignettes",
  link: "/vignettes",
  label: "Vignettes",
  category: "Workspace",
  exact: true,
  icon: () =>  <DnsRoundedIcon />,
  help: () => { alert('clicked help') },
  header: true,
  navbar: true,
  tabs: [],
  body: () => <VignetteBody />
};

export default VignetteDefinition;

import ExampleBody from './body'
import React from 'react'
import BuildIcon from '@material-ui/icons/Build'

/**
 * icons should be selected from [here](https://material-ui.com/components/material-icons/)
 * when a route is avilable to navigation hidden should be set to false or removed
 * @type {{path: string, help: ExampleDefinition.help, exact: boolean, icon: (function()), tabs: (function()), label: string, category: string, body: (function())}}
 */
const ExampleDefinition = {
  path: "/example/:tab",
  link: "/example/users",
  label: "Example",
  category: "Examples",
  hidden: true,
  exact: true,
  icon: () =>  <BuildIcon />,
  help: () => { alert('clicked help') },
  header: true,
  navbar: true,
  tabs: [
    {
      id: "users",
      label: "Users"
    },{
      id: "sign-in",
      label: "Sign-in method"
    },{
      id: "templates",
      label: "Templates"
    },{
      id: "usage",
      label: "Usage"
    }
  ],
  body: () => <ExampleBody />
};

export default ExampleDefinition;

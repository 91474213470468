import SignupBody from './body'
import React from 'react'
import BuildIcon from '@material-ui/icons/Build'

const ConfirmSignupDefinition = {
  path: "/confirmsignup",
  link: "/confirmsignup",
  label: "Confirm Signup",
  category: "Signup",
  hidden: true,
  exact: true,
  icon: () => <BuildIcon />,
  help: () => { alert('clicked help') },
  header: false,
  navbar: false,
  tabs: [],
  body: () => <SignupBody />
};

export default ConfirmSignupDefinition;


const primaryLightColor = "#30363d";
const primaryMainColor = "#353b43";
const primarySecondColor = "#2d343b";
const primaryDarkColor = "#2b3137";
const primaryDarkerColor = "#222930";
const primaryDarkestColor = "#1e242b";

const sightanaOrange = "#FF7F2A";

const mediumGrey = "#AAAAAA";
const almostWhite = "#eee";
const offWhite = "#eaeff1";

const transparentBlack = "rgba(0, 0, 0, 0.12)";

// Login Box colors
const customInputWarningColor = "#ff9800";
const customInputDangerColor = "#f44336";
const customInputSuccessColor = "#4caf50";
const customInputInfoColor = "#00acc1";
const customInputRoseColor = "#e91e63";
const customInputGrayColor = "#999999";
const customInputBorderColor = "#D2D2D2";
const customInputRootLabelColor = mediumGrey;
const customInputInputPlaceholderColor = mediumGrey;
const customInputFromControlColor = "#495057";


const primaryBackgroundGradient135 = 'linear-gradient(135deg, ' + primaryMainColor + ', ' + primaryDarkestColor + ', ' + primaryDarkerColor + ')'
const primaryBackgroundGradient110 = 'linear-gradient(110deg, ' + primaryMainColor + ', ' + primaryDarkestColor + ', ' + primaryDarkerColor + ')'

export {
  primaryMainColor,
  primarySecondColor,
  primaryLightColor,
  primaryDarkColor,
  primaryDarkerColor,
  primaryDarkestColor,
  sightanaOrange,
  almostWhite,
  offWhite,
  mediumGrey,
  transparentBlack,
  customInputWarningColor,
  customInputDangerColor,
  customInputSuccessColor,
  customInputInfoColor,
  customInputRoseColor,
  customInputGrayColor,
  customInputBorderColor,
  customInputRootLabelColor,
  customInputFromControlColor,
  customInputInputPlaceholderColor,
  primaryBackgroundGradient135,
  primaryBackgroundGradient110,
};

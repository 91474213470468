import LogoutBody from './body'
import React from 'react'
import LogoutIcon from '@material-ui/icons/ExitToApp';

const LogoutDefinition = {
  path: "/logout",
  link: "/logout",
  label: "Logout",
  category: "Logout",
  exact: true,
  icon: () => <LogoutIcon />,
  help: () => { alert('clicked help') },
  header: false,
  navbar: false,
  tabs: [],
  body: () => <LogoutBody />
};

export default LogoutDefinition;

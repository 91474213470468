import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

import {
  primaryMainColor,
  customInputBorderColor,
  customInputDangerColor,
  customInputFromControlColor,
  customInputRootLabelColor,
  customInputSuccessColor, customInputInputPlaceholderColor
} from '../../assets/jss/colors'
import { defaultFont } from '../../assets/jss/fonts'

const styles = (theme) => ({
  disabled: {
    "&:before": {
      borderColor: "transparent !important"
    }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: customInputBorderColor + " !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: primaryMainColor
    }
  },
  underlineError: {
    "&:after": {
      borderColor: customInputDangerColor
    }
  },
  underlineSuccess: {
    "&:after": {
      borderColor: customInputSuccessColor
    }
  },
  whiteUnderline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: theme.palette.common.white
    },
    "&:after": {
      borderColor: theme.palette.common.white
    }
  },
  labelRoot: {
    ...defaultFont,
    color: customInputRootLabelColor + " !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    top: "10px",
    letterSpacing: "unset",
    "& + $underline": {
      marginTop: "0px"
    }
  },
  labelRootError: {
    color: customInputDangerColor + " !important"
  },
  labelRootSuccess: {
    color: customInputSuccessColor + " !important"
  },
  formControl: {
    margin: "0 0 17px 0",
    paddingTop: "27px",
    position: "relative",
    "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
      color: customInputFromControlColor
    }
  },
  input: {
    color: customInputFromControlColor,
    height: "unset",
    "&,&::placeholder": {
      fontSize: "14px",
      fontFamily: defaultFont.fontFamily,
      fontWeight: "400",
      lineHeight: "1.42857",
      opacity: "1"
    },
    "&::placeholder": {
      color: customInputInputPlaceholderColor
    }
  },
  whiteInput: {
    "&,&::placeholder": {
      color: theme.palette.common.white,
      opacity: "1"
    }
  }
});

function CustomInput(props) {
  const { classes } = props;
  const {
    formControlProps,
    labelText,
    autoFocus,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    handleChange,
    type
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + " " + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        autoFocus={autoFocus}
        id={id}
        onChange={handleChange}
        {...inputProps}
        type={type}
      />
    </FormControl>
  );
}

CustomInput.propTypes = {
  autoFocus: PropTypes.bool,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool
};

export default withStyles(styles)(CustomInput);

import OverviewDefinition from './pages/overview/definition'
import VignetteDefinition from './pages/vignettes/definition'
import ExampleDefinition from './pages/example/definition'
import LoginDefinition from './pages/login/definition'
import LogoutDefinition from './pages/logout/definition'
import SignupDefinition from './pages/signup/definition'
import ConfirmSignupDefinition from './pages/signupConfirmation/definition'

export const Routes = [
  OverviewDefinition,
  VignetteDefinition,
  ExampleDefinition,
  LoginDefinition,
  LogoutDefinition,
  SignupDefinition,
  ConfirmSignupDefinition,
];

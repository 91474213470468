import React from 'react'
import {render} from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { StrictMode } from 'react'
import store from './store'
import { Provider } from 'react-redux'
import { Amplify } from 'aws-amplify';
import config from './config';

// Log the initial state
// console.log('Initial state: ', store.getState())

// Every time the state changes, log it
// Note that subscribe() returns a function for unregistering the listener
// const unsubscribe = store.subscribe(() =>
//   console.log('State after dispatch: ', store.getState())
// )

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "portal",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
    ]
  }
});

render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)

// // TODO Actually use this to fetch data
if (process.env.REACT_APP_PORTAL_LOCAL_DEV_ENVIRONMENT === "BC277114-A3DD-4735-AC5C-1A6345D8C512") {
  console.log(config);
  console.log(process.env.REACT_APP_PORTAL_API_URL);
  console.log(process.env.REACT_APP_PORTAL_BUCKET);
  console.log(process.env.REACT_APP_PORTAL_IDENTITY_POOL_ID);
  console.log(process.env.REACT_APP_PORTAL_REGION);
  console.log(process.env.REACT_APP_PORTAL_USER_POOL_CLIENT_ID);
  console.log(process.env.REACT_APP_PORTAL_USER_POOL_ID);
}

function sendToAnalytics(metric) {
  /*
  // TODO build out analytics

  const body = JSON.stringify(metric);
  const url = 'https://example.com/analytics';

  // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, body);
  } else {
    fetch(url, { body, method: 'POST', keepalive: true });
  }
  */

  // for now just log to console.log
  console.log(metric);
}

reportWebVitals(sendToAnalytics)

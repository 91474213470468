import { createStore } from 'redux'
import rootReducer from './reducer'

let preloadedState

const persistedSituationString = localStorage.getItem('situation')
const persistedAuthString = localStorage.getItem('auth')
const persistedSignupString = localStorage.getItem('signup')

preloadedState = {};

if (persistedSituationString) {
  preloadedState["situation"] = JSON.parse(persistedSituationString);
}

if (persistedAuthString) {
  preloadedState["auth"] = JSON.parse(persistedAuthString);
}

if (persistedSignupString) {
  preloadedState["signup"] = JSON.parse(persistedSignupString);
}

const store = createStore(rootReducer, preloadedState)

function cleanObject(object, keys) {
  let result = JSON.parse(JSON.stringify(object));

  keys.forEach((item, index) => {
    delete result[item];
  });

  return result;
}

store.subscribe(() => {
  let stateObject = store.getState();

  localStorage.setItem('situation', JSON.stringify(stateObject.situation));

  localStorage.setItem('auth', JSON.stringify(
    cleanObject(
      stateObject.auth,
      ['password','error', 'isAuthenticated']
    )));

  localStorage.setItem('signup', JSON.stringify(
    cleanObject(
      stateObject.signup,
      ['password','error', 'confirm_password', 'confirmationCode']
    )));
})

export default store;

const initialState = {
  email: null,
  password: null,
  error: "",
  isAuthenticated: false,
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case 'auth/email': {
      return {
        ...state,
        email: action.payload
      }
    }
    case 'auth/password': {
      return {
        ...state,
        password: action.payload
      }
    }
    case 'auth/error': {
      return {
        ...state,
        error: action.payload
      }
    }
    case 'auth/login': {
      return {
        ...state,
        isAuthenticated: true
      }
    }
    case 'auth/logout': {
      return {
        ...state,
        isAuthenticated: false
      }
    }
    case 'auth/validate': {
      return {
        ...state,
        isAuthenticated: true
      }
    }
    default:
      return state
  }
}

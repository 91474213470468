import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import HeaderToolbar from './HeaderToolbar'
import store from '../../store'
import { Provider } from 'react-redux'

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
  root: {
    display: 'inherit',
  },
  drawer: {
    display: 'inherit',
  },
  app: {
    display: 'inherit',
  },
  main: {
    display: 'inherit',
  },
  footer: {
    display: 'inherit',
  },
  paper: {
    display: 'inherit',
  },
  searchBar: {
    display: 'inherit',
  },
  searchInput: {
    display: 'inherit',
  },
  block: {
    display: 'inherit',
  },
  contentWrapper: {
    display: 'inherit',
  }
});

function Header(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <Provider store={store}>
      <HeaderToolbar />
      </Provider>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {props.title}
              </Typography>
            </Grid>
            <Grid item>
              <Button className={classes.button} variant="outlined" color="inherit" size="small">
                Web setup
              </Button>
            </Grid>
            <Grid item>

              <Tooltip title="Help">
                <IconButton color="inherit" onClick={props.onHelp}>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);

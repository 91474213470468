import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import store from '../../store'
import gravatar from 'gravatar'
import { useSelector } from 'react-redux'
// import { useHistory } from 'react-router-dom'

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
  paper: {
    display: 'inherit',
  },
  searchBar: {
    display: 'inherit',
  },
  searchInput: {
    display: 'inherit',
  },
  block: {
    display: 'inherit',
  },
  contentWrapper: {
    display: 'inherit',
  }
});

function getAlerts() {
  return <Tooltip title='Alerts • No alerts'>
    <IconButton color='inherit'>
      <NotificationsIcon />
    </IconButton>
  </Tooltip>
}

function getAvatarURL(email) {
  let url = gravatar.url(email, {s: '200', r: 'pg', d: '404'});

  return url;
}

function getGravatarProfileFromURL(url) {
  fetch(url)
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson
    })
    .catch((error) => {
      return {}
    })
}

function getAvatarAlt(email) {
  let url = gravatar.profile_url(email);
  let profile = getGravatarProfileFromURL(url);
  let name = "";

  if (email !== null && email !== undefined) {
    name = email[0].toUpperCase();
  }

  if (profile !== undefined && profile.displayName !== null && profile.displayName !== undefined && profile.displayName.length > 0) {
    name = profile.displayName;
  }

  return name;
}


const selectAuth = state => state.auth;

function HeaderToolbar(props) {
  const { classes } = props;
  const auth = useSelector(selectAuth)
  // const history = useHistory();

  const handleDrawerToggle = () => {
    store.dispatch({ type: 'situation/navigationOpenInMobileToggled', payload: 0 })
  };

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            <Grid item>
              <Link className={classes.link} href="#" variant="body2">
                Go to docs
              </Link>
            </Grid>
            <Grid item>
              {getAlerts()}
            </Grid>
            <Grid item>
              <IconButton color="inherit" className={classes.iconButtonAvatar}>
                <Avatar src={getAvatarURL(auth.email)} alt={getAvatarAlt(auth.email)} />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

HeaderToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderToolbar);

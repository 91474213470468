import { combineReducers } from 'redux'

import situationReducer from './features/situation/situationSlice'
import authReducer from './features/auth/authSlice'
import signupReducer from './features/signup/signupSlice'

const rootReducer = combineReducers({
  situation: situationReducer,
  auth: authReducer,
  signup: signupReducer,
});

export default rootReducer;

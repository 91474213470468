import OverviewBody from './body'
import React from 'react'
import PublicIcon from '@material-ui/icons/Public'

const OverviewDefinition = {
  path: "/",
  link: "/",
  label: "Overview",
  category: "Workspace",
  exact: true,
  icon: () => <PublicIcon />,
  help: () => { alert('clicked help') },
  header: true,
  navbar: true,
  tabs: [],
  body: () => <OverviewBody />
};

export default OverviewDefinition;

const initialState = {
  email: null,
  password: null,
  confirm_password: null,
  isAwaitingConfirmation: false,
  confirmationCode: null,
  newUser: null,
  error: "",
}

export default function signupReducer(state = initialState, action) {
  switch (action.type) {
    case 'signup/email': {
      return {
        ...state,
        email: action.payload
      }
    }
    case 'signup/password': {
      return {
        ...state,
        password: action.payload
      }
    }
    case 'signup/confirmationCode': {
      return {
        ...state,
        confirmationCode: action.payload
      }
    }
    case 'signup/confirm_password': {
      return {
        ...state,
        confirm_password: action.payload
      }
    }
    case 'signup/newuser': {
      return {
        ...state,
        newUser: action.payload,
        isAwaitingConfirmation: true
      }
    }
    case 'signup/error': {
      return {
        ...state,
        error: action.payload
      }
    }
    default:
      return state
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CustomInput from "../../components/material/CustomInput/CustomInput";
import logo from '../../components/Sightana_Logo.svg'
import {
  almostWhite, customInputDangerColor,
  primaryDarkestColor,
  sightanaOrange
} from '../../components/assets/jss/colors'
import store from '../../store'
import { useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import validator from 'validator'
import { Auth } from "aws-amplify";
import { useHistory } from 'react-router-dom'

const styles = (theme) => ({
  signupPaper: {
    width: '400px',
    margin: 'auto',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  signupWrapper: {
    margin: '40px 16px',
  },
  signupLogo: {
    width: "150px",
    padding: "20px",
  },
  signupError: {
    color: customInputDangerColor,
    fontWeight: "900",
    textAlign: "center",
    width: "100%",
    padding: "10px",
    margin: "10px",
    display: "block",
  },
  signupButton: {
    background: almostWhite,
    width: "100%",
    color: primaryDarkestColor,
    "&:hover": {
      fontWeight: "bold",
      background: sightanaOrange,
      color: primaryDarkestColor
    }
  },
});

function handleChange(event) {
  store.dispatch({ type: 'signup/'+event.currentTarget.id, payload: event.currentTarget.value })
}

function setError(error) {
  store.dispatch({ type: 'signup/error', payload: error })
}

async function handleSignup(event, signup, history) {
  event.preventDefault();

  console.log(JSON.stringify(signup));

  try {
    const newUser = await Auth.signUp({
      username: signup.email,
      password: signup.password,
    });
    store.dispatch({ type: 'signup/newuser', payload: newUser });

  } catch (e) {
    console.log(e);
    setError(e.message);
  }
}

function validateForm(signup) {
  let result = true;

  if (signup.email === null || signup.email === undefined || signup.email.length === 0) {
    result = false;
  } else if (!validator.isEmail(signup.email)) {
    result = false;
  }

  if (signup.password === null || signup.password === undefined || signup.password.length === 0) {
    result = false;
  }

  if (signup.confirm_password === null || signup.confirm_password === undefined || signup.confirm_password.length === 0) {
    result = false;
  }

  let error = "Passwords don't match.";
  if (result && signup.password !== signup.confirm_password) {
    result = false;
    if (signup.error !== error) {
      setError(error);
    }
  } else {
    if (signup.error === error) {
      setError('');
    }
  }

  return result;
}


const selectSignup = state => state.signup;

function SignupBody(props) {
  const { classes } = props;
  const signup = useSelector(selectSignup)
  const history = useHistory();

  if (signup.isAwaitingConfirmation) {
    history.push('/confirmsignup');
  }

  store.subscribe(() => {
    let stateObject = store.getState();

    if (stateObject.signup.isAwaitingConfirmation) {
      history.push('/confirmsignup');
    }
  });

  return (
    <Paper className={classes.signupPaper}>
      <div className={classes.signupWrapper}>
        <img className={classes.signupLogo} src={logo} alt="Sightana, Inc."/>
        <div>
          <Form className="form" onSubmit={(event) => (handleSignup(event, signup, history))}>
            <Form.Group size="lg" controlId="email">
            <CustomInput
              autoFocus
              key="email"
              labelText="Email"
              id="email"
              formControlProps={{
                fullWidth: true
              }}
              handleChange={handleChange}
              type="email"
            />
            </Form.Group>
            <Form.Group size="lg" controlId="password">
            <CustomInput
              key="password"
              labelText="Password"
              id="password"
              formControlProps={{
                fullWidth: true
              }}
              handleChange={handleChange}
              type="password"
            />
            </Form.Group>
            <Form.Group size="lg" controlId="confirm_password">
              <CustomInput
                key="confirm_password"
                labelText="Confirm Password"
                id="confirm_password"
                formControlProps={{
                  fullWidth: true
                }}
                handleChange={handleChange}
                type="password"
              />
            </Form.Group>

            <Button type="submit" color="primary" className={classes.signupButton}
                    disabled={!validateForm(signup)}>
              Sign up
            </Button>
            <span className={classes.signupError}>{signup.error}</span>
          </Form>
        </div>
      </div>
    </Paper>
  );
}

SignupBody.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignupBody);

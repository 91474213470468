import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Navigator from './Navigator';
import Header from './header/Header';
import store from '../store';
import { useSelector } from 'react-redux';
import { Copyright, getDrawerWidth } from '../pages/common'
import { Routes } from '../routes'
import { Switch, Route, matchPath, useLocation, useHistory } from 'react-router-dom'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { AppBar } from '@material-ui/core'

import {
  primaryMainColor,
  primaryLightColor,
  primaryDarkColor,
  primarySecondColor,
  sightanaOrange,
  almostWhite,
  offWhite,
  transparentBlack,
  primaryBackgroundGradient135,
  primaryBackgroundGradient110
} from './assets/jss/colors'
import { Auth } from 'aws-amplify'

let theme = createTheme({
  palette: {
    primary: {
      light: primaryLightColor,
      main: primaryMainColor,
      dark: primaryDarkColor
    }
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5
    }
  },
  shape: {
    borderRadius: 8
  },
  props: {
    MuiTab: {
      disableRipple: true
    }
  },
  mixins: {
    toolbar: {
      minHeight: 48
    }
  }
})

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        borderLeft: '1px solid ' + primaryMainColor,
        borderRight: '1px solid ' + primaryDarkColor,
        background: primaryBackgroundGradient135
      }
    },
    MuiButton: {
      label: {
        textTransform: 'none'
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none'
        }
      }
    },
    MuiAppBar: {
      root: {
        borderTop: '1px solid ' + primaryMainColor,
        borderBottom: '1px solid ' + primaryDarkColor,
        background: primaryBackgroundGradient110,
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1)
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0
        }
      }
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1)
      }
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: primarySecondColor
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20
        }
      }
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32
      }
    },
    MuiSvgIcon: {
      root: {
        fill: sightanaOrange,
      },
    },
  }
};

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: getDrawerWidth(),
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: offWhite,
  },
  footer: {
    padding: theme.spacing(2),
    background: offWhite,
  },
  paper: {
    width: '100%',
    margin: '2px',
    overflow: 'hidden'
  },
  searchBar: {
    borderBottom: '1px solid ' + transparentBlack
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
    color: almostWhite
  },
  block: {
    display: 'block'
  },
  contentWrapper: {
    margin: '40px 16px'
  }
};

export function getCurrentRouteInformation(location) {
  let currentRoute = null;

  Routes.forEach((route, index) => {
    let matchNavPath = matchPath(location.pathname, {
      path: route.path
    })

    if (matchNavPath && matchNavPath.isExact) {
      currentRoute = {
        route: route,
        match: matchNavPath,
      }
    }
  })
  return currentRoute
}

export function getTabURI(match, tabSubPath) {
  let destination = match.path

  Object.keys(match.params).forEach((key, index) => {
    let value = match.params[key]

    if (key === 'tab') {
      value = tabSubPath
    }

    destination = destination.replace(':' + key, value)
  })

  return destination;
}

export function getTabURIFromLocation(location, tabSubPath) {
  let info = getCurrentRouteInformation(location);

  return getTabURI(info.match, tabSubPath);
}

function getHeaderAndTabs(classes, route, history, location, handleTabChange) {
  let result = [];

  if (route.header === true) {
    result.push(<Header key="header" classes={classes} title={route.label} onHelp={route.help} />);
  }

  if (route.tabs.length > 0) {

    let selectedTabId = null;

    const matchNavPath = matchPath(location.pathname, {
      path: route.path,
    });

    if (matchNavPath && matchNavPath.isExact) {
      if (matchNavPath.params && matchNavPath.params.tab) {
        selectedTabId = matchNavPath.params.tab;
      }
    }

    let selectedTab = Number.MAX_SAFE_INTEGER;

    route.tabs.forEach( (tab, index) => {
      if (tab.id === selectedTabId) {
        selectedTab = index;
      }
    });

    result.push(
      <AppBar
        key="headerTabsAppBar"
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
      <Tabs key="tabs" value={selectedTab} textColor="inherit" onChange={(event,newlySelectedTabIndex) => {
        if (route.tabs[newlySelectedTabIndex].id !== selectedTabId) {

          history.push(getTabURI(matchNavPath, route.tabs[newlySelectedTabIndex].id))
        }
      }}>
        {
          route.tabs.map( (tab, index) => (
            <Tab key={index} textColor="inherit" label={tab.label} />
          ))
        }
      </Tabs>
      </AppBar>
    );
  }

  return result;
}

const selectSituation = state => state.situation;
const selectAuth = state => state.auth;

function Wrapper(props) {
  const { classes } = props;
  const situation = useSelector(selectSituation)
  const auth = useSelector(selectAuth)

  const location = useLocation();
  const history = useHistory();

  const handleTabChange = (event, newValue) => {

  };

  const handleDrawerToggle = () => {
    store.dispatch({ type: 'situation/navigationOpenInMobileToggled', payload: 0 })
  };

  Auth.currentUserInfo().then(function(user) {
    if (location.pathname !== "/login" && location.pathname !== "/logout" && location.pathname !== "/signup" && location.pathname !== "/confirmsignup") {

      if(user === null || user === undefined || user.attributes.email !== auth.email) {
        history.push("/logout");
      }
    }
  })

  function getNav(classes, route, history, location) {
    let result = [];

    if (route.navbar === true) {
      result.push(<nav key="nav" className={classes.drawer}>
        <Hidden smUp implementation='css'>
          <Navigator
            PaperProps={{ style: { width: getDrawerWidth() } }}
            variant='temporary'
            open={situation.navigationOpenInMobile}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden xsDown implementation='css'>
          <Navigator PaperProps={{ style: { width: getDrawerWidth() } }} />
        </Hidden>
      </nav>);
    }

    return result;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Switch>
          {
            Routes.map((route, index) => (
              <Route
                key={"nav" + index}
                path={route.path}
                exact={route.exact}
                children={
                  getNav(classes, route, history, location)
                }
              />
            ))
          }
        </Switch>
        <div className={classes.app}>
          <Switch>
            {
              Routes.map((route, index) => (
                <Route
                  key={"header" + index}
                  path={route.path}
                  exact={route.exact}
                  children={
                    getHeaderAndTabs(classes, route, history, location, handleTabChange())
                  }
                />
              ))
            }
          </Switch>
          <main className={classes.main}>
            <Switch>
              {
                Routes.map((route, index) => (
                  <Route
                    key={"bodyWrapper" + index}
                    path={route.path}
                    exact={route.exact}
                  >
                    <route.body key={"body" + index} classes={classes} />
                  </Route>
                ))
              }
            </Switch>
          </main>
          <footer className={classes.footer}>
            <Copyright />
          </footer>
        </div>
      </div>
    </ThemeProvider>
  );
}

Wrapper.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Wrapper);

import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import React from 'react'

function SignInMethodTab(props) {
  const { classes } = props

  return (
    <React.Fragment>
    <Paper className={classes.paper}>
    <div className={classes.contentWrapper}>
      <Typography color='textSecondary' align='center'>
        Sign-in method tab
      </Typography>
    </div>
    </Paper>
    </React.Fragment>)
}

SignInMethodTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default SignInMethodTab;

import LoginBody from './body'
import React from 'react'
import LockOpenIcon from '@material-ui/icons/LockOpen';

const LoginDefinition = {
  path: "/login",
  link: "/login",
  label: "Login",
  category: "Login",
  hidden: true,
  exact: true,
  icon: () => <LockOpenIcon />,
  help: () => { alert('clicked help') },
  header: false,
  navbar: false,
  tabs: [],
  body: () => <LoginBody />
};

export default LoginDefinition;

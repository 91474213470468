import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CustomInput from "../../components/material/CustomInput/CustomInput";
import logo from '../../components/Sightana_Logo.svg'
import {
  almostWhite, customInputDangerColor,
  primaryDarkestColor,
  sightanaOrange
} from '../../components/assets/jss/colors'
import store from '../../store'
import { useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import { Auth } from "aws-amplify";
import { useHistory } from 'react-router-dom'

const styles = (theme) => ({
  signupPaper: {
    width: '400px',
    margin: 'auto',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  signupWrapper: {
    margin: '40px 16px',
  },
  signupLogo: {
    width: "150px",
    padding: "20px",
  },
  signupError: {
    color: customInputDangerColor,
    fontWeight: "900",
    textAlign: "center",
    width: "100%",
    padding: "10px",
    margin: "10px",
    display: "block",
  },
  signupButton: {
    background: almostWhite,
    width: "100%",
    color: primaryDarkestColor,
    "&:hover": {
      fontWeight: "bold",
      background: sightanaOrange,
      color: primaryDarkestColor
    }
  },
});

function handleChange(event) {
  store.dispatch({ type: 'signup/'+event.currentTarget.id, payload: event.currentTarget.value })
}

function setError(error) {
  store.dispatch({ type: 'signup/error', payload: error })
}

async function handleSignup(event, signup, history) {
  event.preventDefault();
  console.log(JSON.stringify(signup));

  try {
    await Auth.confirmSignUp(signup.email, signup.confirmationCode);
    history.push("/login");
  } catch (e) {
    if (e.code === "NotAuthorizedException") {
      history.push("/login");
    } else {
      setError(e.message);
    }
  }

}

function validateForm(signup) {
  let result = true;

  if (signup.confirmationCode === null || signup.confirmationCode === undefined || signup.confirmationCode.length === 0) {
    result = false;
  }

  return result;
}


const selectSignup = state => state.signup;

function SignupBody(props) {
  const { classes } = props;
  const signup = useSelector(selectSignup)
  const history = useHistory();

  if (!signup.isAwaitingConfirmation) {
    history.push('/signup');
  }

  return (
    <Paper className={classes.signupPaper}>
      <div className={classes.signupWrapper}>
        <img className={classes.signupLogo} src={logo} alt="Sightana, Inc."/>
        <div>
          <Form className="form" onSubmit={(event) => (handleSignup(event, signup, history))}>
            <Form.Group size="lg" controlId="email">
            <CustomInput
              autoFocus
              key="confirmationCode"
              labelText="Confirmation Code"
              id="confirmationCode"
              formControlProps={{
                fullWidth: true
              }}
              handleChange={handleChange}
              type="tel"
            />
            </Form.Group>

            <Button type="submit" color="primary" className={classes.signupButton}
                    disabled={!validateForm(signup)}>
              Confirm
            </Button>
            <span className={classes.signupError}>{signup.error}</span>
          </Form>
        </div>
      </div>
    </Paper>
  );
}

SignupBody.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignupBody);

import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import RefreshIcon from '@material-ui/icons/Refresh'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import React from 'react'

function UsersTab(props) {
  const { classes } = props

  return (
    <React.Fragment>
    <Paper className={classes.paper}>
    <AppBar className={classes.searchBar} position='static' color='default' elevation={0}>
      <Toolbar>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <SearchIcon className={classes.block} color='inherit' />
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              placeholder='Search by email address, phone number, or user UID'
              InputProps={{
                disableUnderline: true,
                className: classes.searchInput
              }}
            />
          </Grid>
          <Grid item>
            <Button variant='contained' color='primary' className={classes.addUser}>
              Add user
            </Button>
            <Tooltip title='Reload'>
              <IconButton>
                <RefreshIcon className={classes.block} color='inherit' />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
    <div className={classes.contentWrapper}>
      <Typography color='textSecondary' align='center'>
        No users for this project yet
      </Typography>
    </div>
    </Paper>
    </React.Fragment>)
}

UsersTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default UsersTab;

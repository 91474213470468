import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import logo from '../../components/Sightana_Logo.svg'
import store from '../../store'
import { useHistory } from 'react-router-dom'
import { Auth } from 'aws-amplify'

const styles = (theme) => ({
  logoutPaper: {
    width: '400px',
    margin: 'auto',
    overflow: 'hidden',
  },
  logoutWrapper: {
    margin: '40px 16px',
  },
  logoutLogo: {
    width: "150px",
    padding: "20px",
  },
});

function LogoutBody(props) {
  const { classes } = props;
  const history = useHistory();

  Auth.signOut({ global: true })
  store.dispatch({ type: 'auth/logout' });
  history.push("/login");

  return (
    <Paper className={classes.logoutPaper}>
      <div className={classes.logoutWrapper}>
        <img className={classes.logoutLogo} src={logo} alt="Sightana, Inc."/>
      </div>
    </Paper>
  );
}

LogoutBody.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LogoutBody);

const initialState = {
  navigationOpenInMobile: false
}

export default function situationReducer(state = initialState, action) {
  switch (action.type) {
    case 'situation/navigationOpenInMobileToggled': {
      return {
        ...state,
        navigationOpenInMobile: !state.navigationOpenInMobile
      }
    }
    default:
      return state
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import UsersTab from './tabs/UsersTab'
import { Route, Switch, useLocation } from 'react-router-dom'
import { getTabURIFromLocation } from '../../components/Wrapper'
import SignInMethodTab from './tabs/SignInMethodTab'
import TemplatesTab from './tabs/TemplatesTab'
import UsageTab from './tabs/UsageTab'

const styles = (theme) => ({
  paper: {
    width: '100%',
    margin: '2px',
    overflow: 'hidden'
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
    color: '#eee'
  },
  block: {
    display: 'block'
  },
  addUser: {
    marginRight: theme.spacing(1)
  },
  contentWrapper: {
    margin: '40px 16px'
  }
})

function ExampleBody(props) {
  const { classes } = props

  const location = useLocation();

  return (
    <React.Fragment>
      <Switch>
        <Route key="users" path={getTabURIFromLocation(location, "users")} exact={true} >
          <UsersTab classes={classes} />
        </Route>
        <Route key="sign-in" path={getTabURIFromLocation(location, "sign-in")} exact={true} >
          <SignInMethodTab classes={classes} />
        </Route>
        <Route key="templates" path={getTabURIFromLocation(location, "templates")} exact={true} >
          <TemplatesTab classes={classes} />
        </Route>
        <Route key="usage" path={getTabURIFromLocation(location, "usage")} exact={true} >
          <UsageTab classes={classes} />
        </Route>
      </Switch>
    </React.Fragment>
)

}

ExampleBody.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ExampleBody)

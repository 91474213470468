import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Routes } from '../routes'
import { matchPath, NavLink, Route, Switch, useLocation } from 'react-router-dom'
import logo from './Sightana_Logo.svg'

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white
  },
  navItem: {
    textDecoration: 'none'
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)'
    }
  },
  header: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)'
  },
  itemCategory: {
    // backgroundColor: '#111111',
    background: 'linear-gradient(135deg, #353b43, #1e242b, #222930)',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  logo: {
    fontSize: 24,
    color: theme.palette.common.white
  },
  itemActiveItem: {
    color: '#FF7F2A'
  },
  itemPrimary: {
    fontSize: 'inherit'
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(2)
  }
})

function getCategories() {

  let children = {}
  let result = []

  Routes.forEach((route, index) => {
    if (route.hidden === undefined || route.hidden === false) {
      if (children[route.category] === undefined) {
        children[route.category] = []
        result.push({
          id: route.category,
          children: children[route.category]
        })
      }

      let child = {
        id: route.label,
        icon: route.icon(),
        link: route.link
      }

      const matchNavPath = matchPath(useLocation().pathname, {
        path: route.path,
      });

      if (matchNavPath && matchNavPath.isExact) {
        child['active'] = true
      }

      children[route.category].push(child)
    }
  })

  return result
}

function Navigator(props) {
  const { classes, ...other } = props

  let categories = getCategories();

  return (
    <Drawer variant='permanent' {...other}>
      <List disablePadding>
        <ListItem key="logoSection" className={clsx(classes.firebase, classes.header, classes.itemCategory)}>
          <img src={logo} alt="Sightana, Inc."/>
        </ListItem>
        <ListItem key="locationSection" className={clsx(classes.header, classes.itemCategory)}>

          <Switch>
            {
              Routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <route.icon />

                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary
                    }}
                  >

                    {route.label}

                  </ListItemText>
                </Route>
              ))
            }
          </Switch>

        </ListItem>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem key={id} className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active, link }) => (
              <NavLink key={childId} to={link} className={clsx(classes.navItem)}>
                <ListItem
                  key={childId}
                  button
                  className={clsx(classes.item, active && classes.itemActiveItem)}
                >
                  <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary
                    }}
                  >
                    {childId}
                  </ListItemText>
                </ListItem>
              </NavLink>
            ))}

            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  )
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Navigator)
